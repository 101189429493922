import React, { useEffect } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { Layout } from "../../components/Layout"
import { BallBeat } from "react-pure-loaders"
import { fetchClientsDetailList } from "../../state/analytics/analyticsActions"
import { AnalyticsSection } from "../../style/analytics"
import { ClientAnalyticsDetailsTable } from "../../components/ClientAnalyticsDetailsTable"
import { AnalyticsTab } from "./analyticsTab";

function clientAnalytics({
    analyticsData,
    analyticsDetailList,
    fetchClientsDetailList,
    location = {},
    selectedTab
}) {

    if (location.state === null || undefined) {
        navigate("/")
    }

    let clientId = location.state ? location.state.data.patient_id : " "

    useEffect(() => {
        fetchClientsDetailList(clientId)
    }, [fetchClientsDetailList])

    useEffect(() => {
        if (selectedTab !== "CLIENTS") {
            navigate("/analytics")
        }
    }, [selectedTab])

    return (
        <div>
            <Layout>
                <AnalyticsSection>
                    <AnalyticsTab activeTab="CLIENTS" />
                    <br />
                    <br />

                    <h4>
                        Client ID -{" "}
                        <span style={{ color: "#4299E1" }}>  {location.state ? location.state.data.patient_id : " "}  </span>
                        &nbsp; Client Name -{" "}
                        <span style={{ color: "#4299E1" }}> {location.state ? location.state.data.pname : " "}</span>
                    </h4>
                    {/* <h4>
                        Client Name -{" "}
                        <span >
                            {location.state ? location.state.data.pname : " "}
                        </span>
                    </h4> */}
                    {analyticsData.loading ?
                        (
                            <>
                                <br />
                                <br />
                                <h4>
                                    Loading Client Analytics Details{" "}
                                    <span>
                                        <BallBeat color={"#123abc"} loading={analyticsData.loading} />
                                    </span>
                                </h4>
                            </>
                        ) :
                        analyticsData.error ?
                            (
                                <>
                                    <br />
                                    <h2> Client  Analytics</h2>
                                    <br />
                                    <h4> ERROR ⚠️ FETCHING CLIENT ANALYTICS DETAILS</h4>
                                    <br />
                                    <p style={{ color: "grey" }}>{analyticsData.error}</p>
                                    <br />
                                    <br />
                                </>
                            ) : analyticsDetailList.length === 0 ?
                                (
                                    <>
                                        <br />
                                        <br />
                                        <h4> No session history available for the client</h4>
                                        <br />
                                        <br />
                                        <br />
                                    </>
                                ) :
                                <>
                                    <br />
                                    <ClientAnalyticsDetailsTable clientsDetailList={analyticsDetailList} />
                                </>
                    }

                </AnalyticsSection>
            </Layout>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        analyticsData: state.analytics,
        analyticsDetailList: state.analytics.clientDetails,
        selectedTab: state.analytics.selectedTab
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchClientsDetailList: (clientId) => dispatch(fetchClientsDetailList(clientId)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(clientAnalytics)
