import React from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";

export const ClientAnalyticsDetailsTable = ({ clientsDetailList }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: "Listener ID",
                accessor: "listener_id",
            },
            {
                Header: "Listener Name",
                accessor: "listener_name",
            },
            {
                Header: "Reason",
                accessor: "sessionTopic",
            },
            {
                Header: "Started Time",
                accessor: "started",
            },
            {
                Header: "Duration (In Mins)",
                accessor: "durationInMin",
            },
            {
                Header: "Status",
                accessor: "status",
            },
        ],
        []
    )

    var tableRender = (
        <Table
            columns={columns}
            data={clientsDetailList}
        />
    )

    return <TableStyles>{clientsDetailList ? tableRender : " "} </TableStyles>
}